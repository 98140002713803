import dayjs from "dayjs";
import { isValidPhoneNumber } from "libphonenumber-js";
import * as Yup from "yup";

declare module "yup" {
  interface StringSchema {
    phoneNumber(value?: string): StringSchema;
    slugifyTest(value?: string): StringSchema;
    dayjs(value?: string): StringSchema;
  }
}

/**
 * METHODS
 */

Yup.addMethod(Yup.string, "phoneNumber", function (message) {
  return Yup.string().test("phoneNumberTest", message, (value = "") => {
    if (!value) {
      return true;
    }

    return isValidPhoneNumber?.(value);
  });
});

Yup.addMethod(Yup.string, "slugifyTest", function (message) {
  return Yup.string().test("slugifyTest", message, (value = "") => {
    if (!value) {
      return true;
    }

    return /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/.test(value);
  });
});

Yup.addMethod(Yup.string, "dayjs", function method(message) {
  return this.test("dayjs", message, function validate(value) {
    if (!value) {
      return true;
    }

    return dayjs(value).isValid();
  });
});

export const slugValidator = Yup.string()
  .slugifyTest("Slug is not valid")
  .required("This is a required field");

export const phoneNumberValidator = Yup.string()
  .phoneNumber("Phone number is not valid")
  .required("Phone number is required")
  .nullable();

export const emailValidator = Yup.string().email().required("Email is required");

export const stringGenericValidator = Yup.string().required("This field is required");

const MAX_FILE_SIZE = 10240000; // 10MB

export const fileValidator = Yup.mixed()
  .required("This is a required field")
  .test("is-valid-size", "Max allowed size is 10MB", value => value.size <= MAX_FILE_SIZE);

export const dateValidator = Yup.string().dayjs("Date is invalid").nullable();
