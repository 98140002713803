import { type User } from "@/types/user";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ProfileFormType, getProfileForm } from "@configs/features/profile";

import fetchClient from "@services/fetch-client";

/**
 * Custom hook to fetch and manage user profile data.
 * @param user - The user object.
 * @returns The result of the query.
 */
export function useProfile(user: User) {
  return useQuery<User>({
    queryKey: ["profile"],
    queryFn: async () => fetchClient.get("/api/user"),
    keepPreviousData: true,
    initialData: user,
    enabled: !!user?.email,
  });
}

/**
 * Custom hook for updating a user's profile.
 * @returns A mutation function that updates the user's profile.
 */
export function useUpdateProfile() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (data: Partial<User>) => fetchClient.put("/api/user/update", data),
    onSuccess: () => {
      client.invalidateQueries(["profile"]);
    },
  });
}

/**
 * Custom hook for creating certifications.
 * This hook uses `useMutation` from `react-query` to handle the mutation logic.
 * It sends a POST request to the "/api/user/certifications" endpoint with the provided data.
 * After a successful mutation, it invalidates the "profile" query in the query cache.
 * @returns The `useMutation` hook result.
 */
export function useCreateCertifications() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (data: FormData) => {
      const response = await fetch("/api/user/certifications", { method: "POST", body: data });
      return response.json();
    },
    onSuccess: () => client.invalidateQueries(["profile"]),
  });
}

/**
 * Custom hook for creating education data for a user.
 * @returns A mutation function that can be used to create education data.
 */
export function useCreateEducation() {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async data => await fetchClient.post("/api/user/education", data),
    onSuccess: () => client.invalidateQueries(["profile"]),
  });
}

/**
 * Custom hook that returns a form object for the user's profile.
 *
 * @param user - The user object.
 * @param module - The module name.
 * @returns An object containing the form object and the profile form.
 */
export function useProfileForm(user: User, module: string) {
  const profileForm = getProfileForm(user, module);
  const { initialValues, schema } = getSchemaAndInitialValues(profileForm);

  const form = useForm({ defaultValues: initialValues, resolver: yupResolver(schema) });

  return { ...form, profileForm };
}

// ==============================

function getSchemaAndInitialValues(profileForm: ProfileFormType) {
  const schema: { [key: string]: yup.AnySchema } = {};
  const initialValues: { [key: string]: unknown } = {};

  profileForm
    .flatMap(({ fields }) => fields)
    .forEach(({ id, validation, value }) => {
      schema[id] = validation;
      initialValues[id] = value;
    });

  return { schema: yup.object().shape(schema), initialValues };
}
